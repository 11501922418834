import React from 'react';
import ReferenzenAuswahl from '../components/referenzen/index-select';
import Layout from '../components/layout'
import { graphql } from "gatsby"

function getWindowSearchParameters()  {
  if(typeof window !== 'undefined'){
    window.location.search
  }
}

const Referenzen_ = ({ data }) => {

  let filter = [];

  try {

    const urlParams = new URLSearchParams(getWindowSearchParameters());
    const myParam = urlParams.get('f')

    if (myParam) {
      filter = myParam.split(',');
    }

  } catch (e) {
    console.error(e)
  }

  return (
    <Layout title="Referenzen Auswahl">
      <ReferenzenAuswahl data={data} filter={filter} />
    </Layout>
  )
}
export const query = graphql`
{
    allProject(sort: {fields: [konstruktionReihenfolge], order: ASC}) {
      edges {
        node {
          id
          titelLagebeschreibung
          beschreibung
          konstruktionsVariante
          konstruktionReihenfolge
          sonderkonstruktion
          gelander
          beschichtung
          entwasserungebene
          konstruktionsVariante
          fussboden
          projektgrosse
          ort
          slug
          path
          projekt
          teaser {
            file {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    }
  }
  
`

export default Referenzen_